<!-- 约定数据标准 -->
<template>
  <div class="appoint-container">
    <header-area :stepActive="3"></header-area>
    <div class="con borderTop" v-for="(item, index) in shopData" :key="index">
      <div class="conHd">
        <img v-if="item.shopLogo" :src="item.shopLogo" class="headerImg" /><span>{{item.shopName}}</span>
      </div>
      <div class="conBd">
        <div @click="() => { clickItemFun(itemTwo.type, index) }" :class="(item.type == 1 && itemTwo.type ==  'base') || (item.type == 2 && itemTwo.type ==  'register') || (item.type == 3 && itemTwo.type ==  'notice') ? 'con-item': 'con-item con-item-gery'" v-for="(itemTwo, indexTwo) in item.dataStands" :key="indexTwo">
          <div v-if="itemTwo.type == 'base'" class="con-hd">
            <span>【基础版】</span><el-radio :label="1" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div v-if="itemTwo.type == 'register'" class="con-hd">
            <span>【专业版】</span><el-radio :label="2" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div v-if="itemTwo.type == 'notice'" class="con-hd">
            <span>【旗舰版】</span><el-radio :label="3" v-model="item.type">&nbsp;</el-radio>
          </div>
          <div class="con-bd">
            <ul>
              <li><span>首响</span> <span class="bd-data">{{itemTwo['首响']}}s</span></li>
              <li><span>平响</span> <span class="bd-data">{{itemTwo['平响']}}s</span></li>
              <li><span>转换率</span> <span class="bd-data">{{itemTwo['转换率']}}s</span></li>
              <li><span>三分钟回复率</span> <span class="bd-data">{{itemTwo['三分钟回复率']}}%</span></li>
              <li><span>五分钟回复率</span> <span class="bd-data">{{itemTwo['五分钟回复率']}}%</span></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="conFt">
        <span class="ft-one">注:</span>
        <span>甲方因店铺流量增加导致实际日咨询量超过协议日咨询量10%，则乙方有权向甲方申请增加客服坐席，如甲方不同意增加，则因咨询量过大导致的数据下滑、客户投诉等问题，乙方不承担责任，且当月KPI按照正常打标计算。</span>
      </div>
    </div>
    <div class="sixth">
      <el-button type="primary" @click="() => { saveAgreedData() }">下一步</el-button>
      <el-button plain class="sett-return" @click="() => { $router.push(`/quoted/setting-content?id=${$route.query.id}`) }">上一步：配置服务内容</el-button>
      <span class="sett-total">{{ (price).toFixed(2) }}</span>
      <span class="sett-rmb">¥</span>
      <span class="sett-money">配置费用：</span>
    </div>
  </div>
</template>

<script>
import { agreedData, saveAgreedData, calculationPrice } from "../../service/quoted.js"
import HeaderArea from "./components/header.vue"
export default {
  name: "appointStand",
  components: {
    HeaderArea
  },
  props: {},
  data() {
    return {
      radio: true,
      shopData: [],
      price: 0
    };
  },
  mounted() {
    this.agreedData()
    // this.calculationPrice()
    
  },
  methods: {
    async calculationPrice () {
      let { data } = await calculationPrice({id: this.$route.query.id})
        this.price = data.price || 0
    },
    async saveAgreedData(type) { // 预定数据保存
      let shopData = JSON.parse(JSON.stringify(this.shopData))
      let newArr = []
      shopData.map((item) => {
        if (item.dataStands) {
          item.dataStands.map((itemTwo)=>{
            if ((itemTwo.type == 'base' && item.type == 1) || (itemTwo.type == 'register' && item.type == 2) || (itemTwo.type == 'notice' && item.type == 3)) {
              newArr.push({id: item.id, baseServiceShopId: item.shopId, dataStands: JSON.stringify(itemTwo)})
            }
          })
        }
      })
      await saveAgreedData({shopData: newArr})
      if (type == 'calculation') {
        let { data } = await calculationPrice({id: this.$route.query.id})
        this.price = data.price || 0
      } else {
        this.$router.push(`/quoted/confirm?id=${this.$route.query.id}`)
      }
    },
    clickItemFun(type, i) { // 
      let radio = 0
      let shopData = [...this.shopData]
      switch(type) {
        case 'base': radio = 1; break;
        case 'register': radio = 2; break;
        case 'notice': radio = 3; break;
      }
      shopData.map((item, index)=>{
        if (index == i) {
          item.type = radio
        }
      })
      this.shopData = shopData
      this.saveAgreedData('calculation')
    },
    async agreedData() { // 约定数据详情
      let { data } = await agreedData({id: this.$route.query.id})
      this.shopData = data
      this.saveAgreedData('calculation')
    },
  },
};
</script>

<style lang="less" scoped>
.appoint-container {
  padding-bottom: 100px;
  .borderTop {
    border-top: 16px solid #eee;
  }
  /deep/ .el-radio__label {
    opacity: 0;
    width: 0;
  }
  .header {
    box-sizing: border-box;
    padding: 25px 127px 32px 100px;
    border-bottom: 1px solid rgba(217, 217, 217, 100);
    .con-title {
      display: flex;
      .tit-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 330px;
        .address {
          // margin-top: 23px;
          font-size: 22px;
        }
        .el-icon-location {
          font-size: 22px;
          margin-right: 8px;
          color: #1890ff;
        }
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f2f9ff;
          color: #1890ff;
          border: 1px solid #1890ff;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .tit-center {
        display: flex;
        .center-fir,
        .center-sec {
          display: flex;
          line-height: 42px;
          .center-fir-margin {
            margin-left: 72px;
          }
          .center-fir-third {
            // margin-right: 57px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              top: 6px;
              left: 104px;
              display: inline-block;
              width: 1px;
              height: 72px;
              background: #bbb;
            }
          }
          .center-fir-con {
            display: inline-block;
            height: 42px;
          }
        }
        .sec-margin {
          margin-left: 110px;
        }
        .sec-width {
          width: 250px;
          text-align: left;
        }
        .center-sec-icon {
          margin-right: 16px;
        }
        .center-sec-con {
          display: inline-block;
          // margin-top: 23px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
  .step {
    position: relative;
    box-sizing: border-box;
    margin-top: 48px;
    // height: 149px;
    // line-height: 149px;
  }
  .gap {
    height: 16px;
    background: #eee;
    margin: 32px 0 0;
  }
  .con {
    .conHd {
      display: flex;
      align-items: center;
      height: 75px;
      padding-left: 237px;
      background-color: #f6f9ff;
      text-align: left;
      border-bottom: 1px solid #b5d1ff;
      .headerImg {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 24px;
      }
      span {
        display: inline-block;
        font-size: 18px;
      }
    }
    .conBd {
      display: flex;
      justify-content: center;
      margin-bottom: 64px;
      .con-item-gery {
        border: 1px solid #d2d4d7 !important;
        .con-hd {
          border-bottom: 1px solid #d2d4d7 !important;
          background: #f9f9f9 !important;
        }
      }
      .con-item {
        cursor: pointer;
        width: 337px;
        height: 339px;
        margin-top: 32px;
        border: 1px solid #1890ff;
        border-radius: 4px;
        &:nth-child(-n + 2) {
          margin-right: 170px;
        }
        .con-hd {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px 0 24px;
          background: #f2f9ff;
          height: 75px;
          border-radius: 4px;
          border-bottom: 1px solid #1890ff;
          span {
            font-size: 18px;
          }
        }
        .con-bd {
          padding: 0 24px;
          ul {
            li {
              display: flex;
              justify-content: space-between;
              margin-top: 24px;
            }
          }
        }
        .bd-data {
          color: #1890ff;
        }
      }
    }
    .conFt {
      width: 1356px;
      padding-left: 244px;
      text-align: left;
      margin-bottom: 80px;
      .ft-one {
        margin-right: 24px;
      }
    }
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0;
  }
  .sixth {
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 101px;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-top: 16px solid #eee;
  }
  .sett-money {
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
    margin-right: 16px;
  }
}
</style>
